<template>
    <div>
        <!-- Submission Confirmation Modal -->
        <b-modal
            id="submission_confirmation_modal"
            title="Confirm"
            size="sm"
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
        >
            <div class="text-center">
                <h1>Are you sure?</h1>
                <p>
                    You are about to finalise your hackathon submission, you
                    cannot undo this.
                </p>

                <base-button
                    type="danger"
                    :pill="true"
                    class="my-4 btn-success"
                    @click="handle_submission_confirmation_modal_confirm()"
                    >Confirm
                </base-button>

                <base-button
                    type="primary"
                    :pill="true"
                    class="my-4 btn-success"
                    @click="$bvModal.hide('submission_confirmation_modal')"
                    >Cancel
                </base-button>
            </div>
        </b-modal>
        <!-- Submission Card -->
        <b-card no-body>
            <b-card-body>
                <b-row>
                    <b-col sm="auto">
                        <h1 class="mb-0 text-nowrap">Submission</h1>
                    </b-col>
                </b-row>
                <b-row v-if="$apollo.queries.get_team_submissions.loading">
                    <b-col
                        class="d-flex align-items-center justify-content-center"
                    >
                        <h1>
                            <i class="fas fa-spinner fa-spin ml-1"></i>
                        </h1>
                    </b-col>
                </b-row>
                <b-row v-else-if="submissions.length > 0">
                    <b-col>
                        <SubmissionForm
                            v-if="!submissions[0].submitted"
                            :form_template="submissions[0].template.schema"
                            :response_data="submissions[0].response"
                            :submit="form_submit_action"
                            @response="submit_form"
                        />
                        <div v-else>
                            <SubmissionRender
                                :form_template="submissions[0].template.schema"
                                :response_data="submissions[0].response"
                            />
                            <small class="text-muted"
                                >Your submission has been submitted for judging.
                            </small>
                        </div>
                    </b-col>
                </b-row>
                <b-row v-else-if="competition_categories.length > 0">
                    <b-col>
                        <SubmissionForm
                            :form_template="
                                competition_categories[0]
                                    .submission_template_schema
                            "
                            :submit="form_submit_action"
                            @response="submit_form"
                        />
                    </b-col>
                </b-row>
                <b-row class="text-right">
                    <b-col> </b-col>
                    <b-col sm="auto" v-if="submissions.length > 0">
                        <base-button
                            v-if="!submissions[0].submitted"
                            type="danger"
                            :pill="true"
                            class="my-4 btn-right"
                            @click="
                                $bvModal.show('submission_confirmation_modal')
                            "
                            :loading="submit_button.loading"
                            :success="submit_button.success"
                        >
                            Finalise Submission
                        </base-button>
                    </b-col>
                    <b-col sm="auto">
                        <base-button
                            v-if="
                                ((submissions.length > 0 &&
                                    !submissions[0].submitted) ||
                                    !(submissions.length > 0)) &&
                                !$apollo.queries.get_team_submissions.loading
                            "
                            type="primary"
                            :pill="true"
                            class="my-4 btn-success btn-right"
                            @click="click_save()"
                            :loading="save_button.loading"
                            :success="save_button.success"
                        >
                            Save
                        </base-button>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
// Queries
import {
    GET_HACKATHON_COMPETITION_CATEGORIES,
    GET_HACKATHON_TEAM_SUBMISSIONS,
} from "@/graphql/queries";

// Mutations
import {
    CREATE_HACKATHON_TEAM_SUBMISSION,
    UPDATE_HACKATHON_TEAM_SUBMISSION,
} from "@/graphql/mutations";

import SubmissionForm from "@/views/Components/FormTemplate/Form.vue";
import SubmissionRender from "@/views/Components/FormTemplate/Render.vue";

export default {
    name: "HackathonTeamSubmissionWidgit",
    components: { SubmissionForm, SubmissionRender },
    props: {
        team_id: {
            type: String,
            description: "The id of the team.",
            default: null,
        },
        hackathon_id: {
            type: String,
            description: "The id of the hackahton.",
            default: null,
        },
    },
    apollo: {
        get_competition_categories: {
            query: GET_HACKATHON_COMPETITION_CATEGORIES,
            result(res) {
                let data = graph_utils.flattened_response(
                    res.data.hackathonHackathon
                );

                this.competition_categories = [];

                if (
                    "competitionCategories" in data &&
                    data.competitionCategories !== null &&
                    data.competitionCategories.length > 0
                ) {
                    data.competitionCategories.forEach((el) => {
                        if (!("submissionTemplate__id" in el)) {
                            return;
                        }
                        this.competition_categories.push({
                            id: el.id,
                            name: el.name,
                            default: el.default,
                            submission_template_id: el.submissionTemplate__id,
                            submission_template_schema:
                                el.submissionTemplate__schema,
                        });
                    });
                }
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            variables() {
                return {
                    hackathon_id: this.hackathon_id,
                };
            },
            update(data) {
                this.apollo_data.get_competition_categories = data;
            },
            skip: true,
        },
        get_team_submissions: {
            query: GET_HACKATHON_TEAM_SUBMISSIONS,
            result(res) {
                let data = graph_utils.flattened_response(
                    res.data.allHackathonTeamSubmission
                );
                this.submissions = [];

                if (data.length > 0) {
                    data.forEach((el) => {
                        this.submissions.push({
                            id: el.id,
                            response: el.response,
                            submitted: el.submitted,
                            template: {
                                id: el.template__id,
                                name: el.template__name,
                                schema: el.template__schema,
                            },
                            competition_category: {
                                id: el.competitionCategory__id,
                                name: el.competitionCategory__name,
                                default: el.template__default,
                            },
                        });
                    });
                }
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            variables() {
                return {
                    team_id: this.team_id,
                };
            },
            update(data) {
                this.apollo_data.get_team_submissions = data;
            },
            skip: true,
        },
    },
    data() {
        return {
            apollo_data: {
                get_competition_categories: null,
                get_team_submissions: null,
            },

            competition_categories: [],
            submissions: [],
            form_submit_action: false,
            save_button: {
                loading: false,
                success: false,
            },
            submit_button: {
                submit: false,
                loading: false,
                success: false,
            },
        };
    },
    methods: {
        // #region Form Actions
        submit_form(submission_data) {
            if (this.submissions.length > 0) {
                this.update_submission(submission_data);
            } else {
                this.create_submission(submission_data);
            }
        },

        update_submission(submission_data) {
            this.save_button.loading = true;
            this.$apollo
                .mutate({
                    mutation: UPDATE_HACKATHON_TEAM_SUBMISSION,
                    variables: {
                        submission_id: this.submissions[0].id,
                        data: JSON.stringify(submission_data),
                        submitted: this.submit_button.submit,
                        // competition_category_id: "" FUTURE
                    },
                })
                .then((res) => {
                    if ("data" in res) {
                        this.$notify({
                            message: "Your team submission has been saved.",
                            timeout: 3000,
                            icon: "ni ni-check-bold",
                            type: "success",
                        });
                        this.save_button.success = true;
                        setTimeout(() => {
                            this.save_button.success = false;
                        }, 3000);
                    } else {
                        this.$notify({
                            message: `Could not save your submission, please try again.`,
                            timeout: 5000,
                            icon: "ni ni-cross-bold",
                            type: "danger",
                        });
                    }
                    this.save_button.loading = false;
                    this.submit_button.loading = false;
                    this.$bvModal.hide("submission_confirmation_modal");
                })
                .catch((res) => {
                    console.log(res);

                    this.$notify({
                        message: `Could not save your submission, please try again.`,
                        timeout: 5000,
                        icon: "ni ni-cross-bold",
                        type: "danger",
                    });
                    this.save_button.loading = false;
                    this.submit_button.loading = false;
                });
        },
        create_submission(submission_data) {
            this.save_button.loading = true;
            this.$apollo
                .mutate({
                    mutation: CREATE_HACKATHON_TEAM_SUBMISSION,
                    variables: {
                        template_id:
                            this.competition_categories[0]
                                .submission_template_id,
                        data: JSON.stringify(submission_data),
                        team_id: this.team_id,
                        competition_category_id:
                            this.competition_categories[0].id,

                        submitted: this.submit_button.submit,
                        // competition_category_id: "" FUTURE
                    },
                })
                .then((res) => {
                    if ("data" in res) {
                        this.$notify({
                            message: "Your team submission has been saved.",
                            timeout: 3000,
                            icon: "ni ni-check-bold",
                            type: "success",
                        });
                        this.save_button.success = true;
                        setTimeout(() => {
                            this.save_button.success = false;
                        }, 3000);
                    } else {
                        this.$notify({
                            message: `Could not save your submission, please try again.`,
                            timeout: 5000,
                            icon: "ni ni-cross-bold",
                            type: "danger",
                        });
                    }
                    this.save_button.loading = false;
                    this.submit_button.loading = false;
                    this.$bvModal.hide("submission_confirmation_modal");
                })
                .catch((res) => {
                    console.log(res);

                    this.$notify({
                        message: `Could not save your submission, please try again.`,
                        timeout: 5000,
                        icon: "ni ni-cross-bold",
                        type: "danger",
                    });
                    this.save_button.loading = false;
                    this.submit_button.loading = false;
                })
                .then(() => {
                    this.$apollo.queries.get_team_submissions.refetch();
                });
        },

        click_save() {
            this.form_submit_action = true;
            return setTimeout(() => {
                this.form_submit_action = false;
            }, 10);
        },

        handle_submission_confirmation_modal_confirm() {
            this.submit_button.loading = true;
            this.submit_button.submit = true;
            this.click_save();
        },

        // #endregion

        // #region Manage Apollo
        manage_get_competition_categories() {
            if (this.hackathon_id) {
                graph_utils.enable_query(
                    this.$apollo.queries.get_competition_categories
                );
            } else {
                graph_utils.disable_query(
                    this.$apollo.queries.get_competition_categories
                );
            }
        },
        manage_get_team_submissions() {
            if (this.team_id) {
                graph_utils.enable_query(
                    this.$apollo.queries.get_team_submissions
                );
            } else {
                graph_utils.disable_query(
                    this.$apollo.queries.get_team_submissions
                );
            }
        },
        // #endregion
    },

    mounted() {
        this.manage_get_competition_categories();
        this.manage_get_team_submissions();
    },
    watch: {
        hackathon_id() {
            this.manage_get_competition_categories();
        },
        team_id() {
            this.manage_get_team_submissions();
        },
    },
};
</script>

<style></style>
