<template>
    <div>
        <b-row>
            <b-col>
                <validation-observer
                    v-slot="{ handleSubmit }"
                    ref="formValidator"
                >
                    <b-form
                        role="form"
                        @submit.prevent="handleSubmit(submit_form)"
                    >
                        <input
                            type="submit"
                            ref="form_template_submit_button"
                            hidden
                        />

                        <b-row
                            v-for="template_item in form_template_obj"
                            :key="template_item.id"
                        >
                            <b-col>
                                <!-- Input -->
                                <base-input
                                    v-if="template_item.input_type == 'input'"
                                    :pill="true"
                                    :label="template_item.display_name"
                                    class="mb-3"
                                    name="above"
                                    :rules="template_item.validation_rules"
                                    :placeholder="template_item.placeholder"
                                    v-model="template_item.value"
                                >
                                </base-input>
                                <!-- TextArea -->
                                <base-input
                                    v-if="
                                        template_item.input_type == 'textarea'
                                    "
                                    :pill="true"
                                    type="textarea"
                                    :rows="
                                        'rows' in template_item.settings
                                            ? String(
                                                  template_item.settings.rows
                                              )
                                            : String(3)
                                    "
                                    :label="template_item.display_name"
                                    class="mb-3"
                                    name="above"
                                    :rules="template_item.validation_rules"
                                    :placeholder="template_item.placeholder"
                                    v-model="template_item.value"
                                >
                                </base-input>
                                <!-- Checkbox -->
                                <b-row
                                    v-if="
                                        template_item.input_type == 'checkbox'
                                    "
                                >
                                    <b-col sm="auto" class="pr-0">
                                        <b-form-checkbox
                                            v-model="template_item.value"
                                            :name="template_item.name"
                                        ></b-form-checkbox>
                                    </b-col>
                                    <b-col sm="auto" class="pl-0">
                                        <label
                                            class="form-control-label"
                                            :class="{
                                                strike:
                                                    !template_item.value &&
                                                    'enable_strike' in
                                                        template_item.settings &&
                                                    template_item.settings
                                                        .enable_strike,
                                            }"
                                        >
                                            {{ template_item.display_name }}
                                            label
                                        </label>
                                    </b-col>
                                </b-row>
                                <!-- Quill -->
                                <!-- <base-input
                                    v-if="template_item.input_type == 'select'"
                                    :rules="template_item.validation_rules"
                                    :label="template_item.display_name"
                                    class="mb-3"
                                    name="above"
                                > -->
                                <html-editor
                                    v-if="template_item.input_type == 'quill'"
                                    :pill="true"
                                    :label="template_item.display_name"
                                    class="mb-3"
                                    :name="template_item.name"
                                    v-model="template_item.value"
                                >
                                </html-editor>
                                <!-- </base-input> -->
                                <!-- Select -->
                                <base-input
                                    v-if="template_item.input_type == 'select'"
                                    :rules="template_item.validation_rules"
                                    :label="template_item.display_name"
                                    class="mb-3"
                                    name="above"
                                    v-model="template_item.value"
                                >
                                    <el-select
                                        v-if="
                                            template_item.input_type == 'select'
                                        "
                                        class="form"
                                        v-model="template_item.value"
                                        :filterable="true"
                                        name="above"
                                    >
                                        <el-option
                                            v-for="option in template_item
                                                .settings.options"
                                            :key="option"
                                            :label="option"
                                            :value="option"
                                        >
                                        </el-option>
                                    </el-select>
                                </base-input>
                            </b-col>
                        </b-row>
                    </b-form>
                </validation-observer>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import { Select, Option } from "element-ui";
export default {
    name: "FormTemplateForm",
    components: {
        HtmlEditor,
        [Select.name]: Select,
        [Option.name]: Option,
    },
    emits: ["response"],
    props: {
        form_template: {
            type: String,
            description: "The form template json string defining the template.",
            default: '{"fields":[]}',
            // #region
            // default: JSON.stringify([
            //     {
            //         id: "input",
            //         name: "input",
            //         display_name: "input",
            //         placeholder: "placeholder",
            //         input_type: "input",
            //         validation_rules: {
            //             required: true,
            //         },

            //         value: null,
            //     },
            //     {
            //         id: "textarea",
            //         name: "textarea",
            //         display_name: "textarea",
            //         placeholder: "placeholder",
            //         input_type: "textarea",
            //         validation_rules: {
            //             required: true,
            //         },
            //         settings: {
            //             rows: 5,
            //         },
            //         value: null,
            //     },
            //     {
            //         id: "checkbox",
            //         name: "checkbox",
            //         display_name: "checkbox",
            //         placeholder: "checkbox",
            //         input_type: "checkbox",
            //         validation_rules: {
            //             required: true,
            //         },
            //         settings: {
            //             default: false,
            //             enable_strike: false,
            //         },
            //         value: null,
            //     },
            //     {
            //         id: "quill",
            //         name: "quill",
            //         display_name: "quill",
            //         placeholder: "placeholder",
            //         input_type: "quill",
            //         validation_rules: {
            //             required: true,
            //         },
            //         value: null,
            //     },
            //     {
            //         id: "select",
            //         name: "select",
            //         display_name: "select",
            //         input_type: "select",
            //         validation_rules: {
            //             required: true,
            //         },
            //         settings: {
            //             options: ["Test", "Test1", "Test2"],
            //         },
            //         value: null,
            //     },
            // ]),
            // #endregion
        },
        response_data: {
            type: String,
            description: "The response data json string",
            default: "[]",
            // #region
            // default: JSON.stringify([
            //     {
            //         id: "input",

            //         value: "test",
            //     },
            // ]),
            // #endregion
        },
        submit: {
            type: Boolean,
            description: "Triggers the submit on this form when toggled",
            default: false,
        },
    },
    data() {
        return {
            form_template_obj: null,
            response_data_obj: null,
        };
    },
    methods: {
        submit_form() {
            let res = [];
            this.form_template_obj.forEach((el) => {
                res.push({
                    id: el.id,
                    value: el.value,
                });
            });
            // Event needs to be emitted here.
            this.$emit("response", res);
        },

        construct_form_template_obj() {
            if ("fields" in JSON.parse(this.form_template)) {
                this.form_template_obj = JSON.parse(this.form_template).fields;
                if (this.form_template_obj) {
                    for (let i = 0; i < this.form_template_obj.length; i++) {
                        if (this.form_template_obj[i].input_type === "quill") {
                            let val = this.get_value_current_value(
                                this.form_template_obj[i].id
                            );
                            if (val === null) {
                                this.form_template_obj[i].value = "";
                            } else {
                                this.form_template_obj[i].value = val;
                            }
                        } else {
                            this.form_template_obj[i].value =
                                this.get_value_current_value(
                                    this.form_template_obj[i].id
                                );
                        }
                    }
                }
            }
        },

        get_value_current_value(id) {
            let response_data_obj = JSON.parse(this.response_data);
            if (response_data_obj !== null) {
                for (let i = 0; i < response_data_obj.length; i++) {
                    if (response_data_obj[i].id === id) {
                        return response_data_obj[i].value;
                    }
                }
            }
            return null;
        },
    },
    mounted() {
        this.construct_form_template_obj();
    },
    watch: {
        submit(new_submit_status) {
            if (new_submit_status) {
                this.$refs.form_template_submit_button.click();
            }
        },
        response_data() {
            this.construct_form_template_obj();
        },
        form_template() {
            this.construct_form_template_obj();
        },
    },
};
</script>

<style>
.strike {
    text-decoration: line-through;
}
</style>
