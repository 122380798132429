<template>
    <div>
        <b-row v-if="!edit_mode">
            <b-col>
                <div class="header d-flex align-items-center">
                    <!-- Mask -->
                    <span class="mask bg-white opacity-8"></span>
                    <!-- Header container -->
                    <b-container >
                        <b-row>
                            <b-col sm="auto">
                                <h1 class="display-2 text-primary pb-0 mb-0">
                                    {{ team_name }}
                                    <!-- <base-button
                                        v-if="is_admin"
                                        type="success"
                                        round
                                        icon
                                        size="sm"
                                        @click="edit_mode = !edit_mode"
                                        class="mb-2"
                                    >
                                        <span class="btn-inner">
                                            <i class="fas fa-edit"> </i>
                                        </span>
                                    </base-button> -->

                                    
                                </h1>
                            </b-col>
                            <b-col class="text-left pt-3 mt-1
                            ">
                                <a
                                        v-if="is_admin"
                                        href="javascript:void(0)"
                                        @click="edit_mode = !edit_mode"
                                    >
                                        <i class="fas fa-edit"></i>
                                    </a>
                            </b-col>
                        </b-row>
                    </b-container>
                </div>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col>
                <b-card no-body v-if="is_admin">
                    <b-card-body>
                        <validation-observer
                            v-slot="{ handleSubmit }"
                            ref="formValidator"
                        >
                            <b-form
                                role="form"
                                @submit.prevent="handleSubmit(update_team_name)"
                            >
                                <base-input
                                    :pill="true"
                                    label="Team Name*"
                                    class="mb-3"
                                    name="Team Name"
                                    :rules="{ required: true }"
                                    placeholder="Enter your Team's name"
                                    v-model="int_team_name"
                                    :disabled="loading"
                                >
                                </base-input>
                                <base-button
                                    type="primary"
                                    :pill="true"
                                    native-type="submit"
                                    class="btn-right"
                                    :disabled="loading"
                                    :loading="loading"
                                    :success="success"
                                >
                                    Save
                                </base-button>
                                <base-button
                                    type="success"
                                    :pill="true"
                                    class="btn-right"
                                    :disabled="loading"
                                    :loading="loading"
                                    :success="success"
                                    @click="edit_mode = !edit_mode"
                                >
                                    Cancel
                                </base-button>
                            </b-form>
                        </validation-observer>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
// Mutations
import { UPDATE_HACKATHON_TEAM } from "@/graphql/mutations";

export default {
    name: "HackathonTeamNameWidgit",
    emits: ["new-team-name"],
    components: {},
    props: {
        team_id: {
            type: String,
            description: "The id of the team.",
            default: null,
        },
        team_name: {
            type: String,
            description: "The name of the team.",
            default: null,
        },
        is_admin: {
            type: Boolean,
            description: "The name of the team.",
            default: false,
        },
    },
    data() {
        return {
            edit_mode: false,
            int_team_name: null,
            loading: false,
            success: false,
        };
    },
    methods: {
        update_team_name() {
            this.$apollo
                .mutate({
                    mutation: UPDATE_HACKATHON_TEAM,
                    variables: {
                        team_id: this.team_id,
                        name: this.int_team_name,
                    },
                })
                .then((res) => {
                    this.edit_mode = false;
                    $emit("hackathon_team_name_update");
                })
                .catch((res) => {
                    // TODO: Create notification that something went wrong.
                });
        },
    },

    mounted() {
        this.int_team_name = this.team_name;
    },
    watch: {
        team_name(new_team_name) {
            this.int_team_name = new_team_name;
        },
    },
};
</script>

<style></style>
