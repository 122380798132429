<template>
    <div>
        <b-row>
            <b-col>
                <b-row
                    v-for="template_item in form_template_obj"
                    :key="template_item.id"
                >
                    <b-col>
                        <div
                            v-if="
                                ['input', 'textarea', 'select'].includes(
                                    template_item.input_type
                                )
                            "
                        >
                            <h3>
                                {{ template_item.display_name }}
                            </h3>
                            <p>{{ template_item.value }}</p>
                        </div>
                        <div v-else-if="template_item.input_type == 'quill'">
                            <h3>
                                {{ template_item.display_name }}
                            </h3>
                            <div v-html="template_item.value"></div>
                        </div>
                        <div v-else-if="template_item.input_type == 'checkbox'">
                            <h3>
                                {{ template_item.display_name }}
                            </h3>
                            <p>
                                {{ template_item.value ? "Yes": "No" }}
                            </p>
                        </div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import { Select, Option } from "element-ui";
export default {
    name: "FormTemplateForm",
    components: {
        HtmlEditor,
        [Select.name]: Select,
        [Option.name]: Option,
    },
    props: {
        form_template: {
            type: String,
            description: "The form template json string defining the template.",
            default: '{"fields":[]}',
        },
        response_data: {
            type: String,
            description: "The response data json string",
            default: "[]",
        },
    },
    data() {
        return {
            form_template_obj: null,
            response_data_obj: null,
        };
    },
    methods: {
        construct_form_template_obj() {
            if ("fields" in JSON.parse(this.form_template)) {
                this.form_template_obj = JSON.parse(this.form_template).fields;
                if (this.form_template_obj) {
                    for (let i = 0; i < this.form_template_obj.length; i++) {
                        if (this.form_template_obj[i].input_type === "quill") {
                            let val = this.get_value_current_value(
                                this.form_template_obj[i].id
                            );
                            if (val === null) {
                                this.form_template_obj[i].value = "";
                            } else {
                                this.form_template_obj[i].value = val;
                            }
                        } else {
                            this.form_template_obj[i].value =
                                this.get_value_current_value(
                                    this.form_template_obj[i].id
                                );
                        }
                    }
                }
            }
        },

        get_value_current_value(id) {
            let response_data_obj = JSON.parse(this.response_data);
            if (response_data_obj !== null) {
                for (let i = 0; i < response_data_obj.length; i++) {
                    if (response_data_obj[i].id === id) {
                        return response_data_obj[i].value;
                    }
                }
            }
            return null;
        },
    },
    mounted() {
        this.construct_form_template_obj();
    },
    watch: {
        response_data() {
            this.construct_form_template_obj();
        },
        form_template() {
            this.construct_form_template_obj();
        },
    },
};
</script>

<style>
.strike {
    text-decoration: line-through;
}
</style>
