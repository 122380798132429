<template>
    <b-row>
        <b-col>
            <div>
                <!-- Delete Modal -->
                <b-modal
                    id="d_modal"
                    title="Confirm"
                    size="sm"
                    no-close-on-backdrop
                    no-close-on-esc
                    hide-footer
                >
                    <div class="text-center">
                        <h1>Are you sure?</h1>
                        <p>
                            You are about to delete the team resource:<br />
                            <strong>"{{ d_modal.d_resource.name }}"</strong>
                        </p>
                        <base-button
                            type="danger"
                            :pill="true"
                            class="my-4 btn-success"
                            @click="d_resource()"
                            >Delete
                        </base-button>
                        <base-button
                            type="primary"
                            :pill="true"
                            class="my-4 btn-success"
                            @click="$bvModal.hide('d_modal')"
                            >Cancel
                        </base-button>
                    </div>
                </b-modal>
                <!-- CU Modal -->
                <b-modal
                    id="cu_modal"
                    :title="cu_modal.mode == 'c' ? 'Create' : 'Edit'"
                    size="md"
                    no-close-on-backdrop
                    no-close-on-esc
                    hide-footer
                >
                    <b-row>
                        <b-col>
                            <validation-observer
                                v-slot="{ handleSubmit }"
                                ref="formValidator"
                            >
                                <b-form
                                    role="form"
                                    @submit.prevent="handleSubmit(submit_form)"
                                >
                                    <base-input
                                        :pill="true"
                                        label="Resource Name*"
                                        class="mb-3"
                                        name="Resource Name"
                                        :rules="{ required: true }"
                                        placeholder="Resource Name"
                                        v-model="cu_modal.cu_resource.name"
                                    >
                                    </base-input>
                                    <base-input
                                        :pill="true"
                                        label="Resource Link*"
                                        class="mb-3"
                                        name="Resource Link"
                                        :rules="{ required: true }"
                                        placeholder="Resource Link"
                                        v-model="cu_modal.cu_resource.link"
                                    >
                                    </base-input>
                                </b-form>
                            </validation-observer>
                        </b-col>
                    </b-row>
                    <b-row class="text-right">
                        <b-col>
                            <base-button
                                type="success"
                                :pill="true"
                                class="my-4"
                                @click="$bvModal.hide('cu_modal')"
                                >Cancel
                            </base-button>
                            <base-button
                                type="primary"
                                :pill="true"
                                class="my-4"
                                @click="cu_resource()"
                                >{{
                                    cu_modal.mode == "c" ? "Create" : "Update"
                                }}
                            </base-button>
                        </b-col>
                    </b-row>
                </b-modal>
                <!-- Body -->
                <b-card no-body>
                    <b-card-header>
                        <b-row>
                            <b-col sm="auto">
                                <h1 class="mb-0 text-nowrap">Resources</h1>
                            </b-col>
                            <b-col class="pt-2">
                                <a
                                    v-if="team.is_admin"
                                    href="javascript:void(0)"
                                    @click="edit_mode = !edit_mode"
                                >
                                    <i class="fas fa-edit"></i>
                                </a>
                            </b-col>
                            <b-col
                                sm="auto"
                                class="text-right"
                                v-if="edit_mode"
                            >
                                <el-tooltip content="Add" placement="top">
                                    <base-button
                                        type="primary"
                                        round
                                        icon
                                        size="sm"
                                        @click="show_cu_modal()"
                                    >
                                        <span class="btn-inner"
                                            ><i class="fa fa-plus"></i
                                        ></span>
                                    </base-button>
                                </el-tooltip>
                            </b-col>
                        </b-row>
                    </b-card-header>
                    <b-card-body>
                        <b-row v-if="edit_mode && team.is_admin">
                            <b-col>
                                <el-table
                                    class="table-responsive align-items-center table-flush no-lines"
                                    header-row-class-name="thead-light"
                                    row-class-name="table-rows"
                                    :show-header="true"
                                    :data="resources"
                                >
                                    <el-table-column
                                        label="Name"
                                        min-width="100px"
                                        prop="name"
                                        sortable
                                    >
                                        <template v-slot="{ row }">
                                            {{ row.name }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        label="Link"
                                        min-width="240px"
                                        sortable
                                    >
                                        <template v-slot="{ row }">
                                            {{ row.link }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        align="center"
                                        v-if="team.is_admin"
                                    >
                                        <div
                                            slot-scope="{ row }"
                                            class="table-actions"
                                        >
                                            <el-tooltip
                                                content="Edit"
                                                placement="top"
                                            >
                                                <a
                                                    href="#!"
                                                    @click.prevent="
                                                        show_cu_modal(row)
                                                    "
                                                    v-if="!row.template_managed"
                                                    class="table-action"
                                                    data-toggle="tooltip"
                                                    data-original-title="Edit"
                                                >
                                                    <i class="fas fa-edit"></i>
                                                </a>
                                            </el-tooltip>
                                            <el-tooltip
                                                content="Delete"
                                                placement="top"
                                            >
                                                <a
                                                    v-if="!row.template_managed"
                                                    href="#!"
                                                    @click.prevent="
                                                        show_d_modal(row)
                                                    "
                                                    class="table-action table-action-delete"
                                                    data-toggle="tooltip"
                                                    data-original-title="Delete product"
                                                >
                                                    <i class="fas fa-trash"></i>
                                                </a>
                                            </el-tooltip>
                                        </div>
                                    </el-table-column>
                                </el-table>
                            </b-col>
                        </b-row>
                        <b-row v-if="!edit_mode">
                            <b-col>
                                <b-row
                                    v-for="resource in resources"
                                    :key="resource.id"
                                >
                                    <b-col>
                                        <a
                                            v-if="
                                                resource.link.startsWith('http')
                                            "
                                            :href="resource.link"
                                            target="_blank"
                                            >{{ resource.name }}</a
                                        >
                                        <a v-else
                                            >{{ resource.name }}:
                                            {{ resource.link }}
                                        </a>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
            </div>
        </b-col>
    </b-row>
</template>

<script>
// Components
import {
    Table,
    TableColumn,
    DropdownMenu,
    DropdownItem,
    Dropdown,
    Tooltip,
} from "element-ui";
// Queries
import { GET_HACKATHON_TEAM_RESOURCES } from "@/graphql/queries";

// Mutations
import {
    CREATE_HACKATHON_TEAM_RESOURCE,
    UPDATE_HACKATHON_TEAM_RESOURCE,
    DELETE_HACKATHON_TEAM_RESOURCE,
} from "@/graphql/mutations";

export default {
    name: "HackathonTeamResources",
    components: {
        [Tooltip.name]: Tooltip,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Dropdown.name]: Dropdown,
        [DropdownItem.name]: DropdownItem,
        [DropdownMenu.name]: DropdownMenu,
    },
    emits: [],
    props: {
        team_id: {
            type: String,
            description: "The id of the team.",
            default: null,
        },
    },
    apollo: {
        get_hackathon_team_resources: {
            query: GET_HACKATHON_TEAM_RESOURCES,
            result(res) {
                this.resources = [];
                let f_data = graph_utils.flatten_objects_recursive(
                    graph_utils.apollo_to_obj_recursive(
                        res.data.allHackathonTeamTeamResource
                    )
                );

                f_data.forEach((el) => {
                    let template_managed = true;
                    if (!el.teamResourceTemplateItem) {
                        template_managed = false;
                    }

                    this.resources.push({
                        id: el.id,
                        name: el.name,
                        link: el.url,
                        template_managed: template_managed,
                    });
                });

                this.team.id = res.data.hackathonTeam.id;
                this.team.is_admin = res.data.hackathonTeam.isAdmin;
                this.team.is_member = res.data.hackathonTeam.isMember;
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            variables() {
                return {
                    team_id: this.team_id,
                };
            },
            update(data) {
                this.apollo_data.get_hackathon_team_resources = data;
            },
            skip: true,
        },
    },
    data() {
        return {
            apollo_data: {
                get_hackathon_team_resources: {},
            },
            cu_modal: {
                cu_resource: {
                    id: null,
                    name: "",
                    link: "",
                },
                mode: "c", // c u d
            },
            d_modal: {
                d_resource: {
                    id: null,
                    name: null,
                    link: null,
                },
            },

            team: {
                id: null,
                is_admin: false,
                is_member: false,
            },

            edit_mode: false,

            delete_item: {
                name: null,
            },
            resources: [
                // {
                //     link: "https://google.com",
                //     name: "Google.com",
                //     id: "ID",
                // },
            ],
        };
    },
    methods: {
        // Apollo Methods
        manage_get_hackathon_team_resources() {
            if (this.team_id != null) {
                graph_utils.enable_query(
                    this.$apollo.queries.get_hackathon_team_resources
                );
            } else {
                graph_utils.disable_query(
                    this.$apollo.queries.get_hackathon_team_resources
                );
            }
        },

        // Delete Modal
        show_d_modal(resource) {
            this.d_modal.d_resource = resource;
            this.$bvModal.show("d_modal");
        },

        d_resource() {
            this.$apollo
                .mutate({
                    mutation: DELETE_HACKATHON_TEAM_RESOURCE,
                    variables: {
                        resource_id: this.d_modal.d_resource.id,
                    },
                })
                .then((res) => {
                    this.$bvModal.hide("d_modal");
                    // Refetching the program data
                    this.$apollo.queries.get_hackathon_team_resources.refetch();
                })
                .catch((res) => {
                    // TODO: Create notification that something went wrong.
                });
        },

        // Create | Update MOdal
        show_cu_modal(resource) {
            if (resource) {
                this.cu_modal.mode = "u";
                this.cu_modal.cu_resource = resource;
            } else {
                this.cu_modal.mode = "c";
                this.cu_modal.cu_resource = {
                    id: null,
                    name: null,
                    link: null,
                };
            }
            this.$bvModal.show("cu_modal");
        },

        cu_resource() {
            let mutation = null;
            let variables = {};

            if (this.cu_modal.mode == "c") {
                mutation = CREATE_HACKATHON_TEAM_RESOURCE;
                variables = {
                    team_id: this.team_id,
                    name: this.cu_modal.cu_resource.name,
                    url: this.cu_modal.cu_resource.link,
                };
            } else if (this.cu_modal.mode == "u") {
                mutation = UPDATE_HACKATHON_TEAM_RESOURCE;
                variables = {
                    resource_id: this.cu_modal.cu_resource.id,
                    name: this.cu_modal.cu_resource.name,
                    url: this.cu_modal.cu_resource.link,
                };
            }

            this.$apollo
                .mutate({
                    mutation: mutation,
                    variables: variables,
                })
                .then((res) => {
                    this.$bvModal.hide("cu_modal");
                    // Refetching the program data
                    this.$apollo.queries.get_hackathon_team_resources.refetch();
                })
                .catch((res) => {
                    // TODO: Create notification that something went wrong.
                });
        },
    },
    mounted() {
        this.manage_get_hackathon_team_resources();
    },
    watch: {
        team_id() {
            this.manage_get_hackathon_team_resources();
        },
    },
};
</script>

<style></style>
